@import '../../../styles/screenSizes.scss';
@import '../../../styles/mixins.scss';

.partners-landing-container {
    @include container;
    // background: #f4fcfd url('../assets/imgs/BG.png') 0% 0% no-repeat padding-box;

    .list-of-partners {
        @include list-of-content;
    }
}
