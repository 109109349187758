@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.FAQ-content {
    @include list-of-content;
    max-width: 100%;
    margin-bottom: 100px;
    margin-top: 0;
    .faq-img {
        width: 42%;
    }

    .accordion-container {
        width: 48%;

        P[other='title'] {
            text-align: right;
            margin-bottom: 1.5rem;
        }

        .accordion {
            .card-container {
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #e1f7fc;
                padding-bottom: 0.5rem;
                padding: 0;
                margin-bottom: 1.25rem;
                .card-header {
                    // background-color: red;
                    background-color: transparent;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    border: none;
                    padding: 0;
                    padding-bottom: 1.25rem;

                    P {
                        margin-bottom: 0;
                        text-align: right;
                        max-width: 90%;
                    }
                    .faq-img-container {
                        width: 24px;
                        height: 24px;
                        border: 1px solid $primary;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .card-content {
                    text-align: right;
                    .answer-container {
                        .answer-content {
                            margin: 10px 0px;
                            P[other='view'] {
                                font-size: 17px;
                                margin-bottom: 0;
                                text-align: right;
                                color: $text-dark;
                            }
                            P[other='content'] {
                                font-size: 15px;
                                color: $text-light;
                                font-weight: lighter;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .FAQ-content {
        // @include list-of-content;
        // max-width: 100%;
        // margin-top: 100px;
        .faq-img {
            width: 80%;
            // border: solid;
        }

        .accordion-container {
            width: 90%;
            margin-top: 2rem;
        }
    }
}
