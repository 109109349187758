@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.doctor-video-call-card-container {
    @include doctor-profile-card-container;
    border: 1px solid $border;
    .content {
        @include doctor-profile-card-content;
        padding-right: 0;
        P[other='price'],
        P[other='days'] {
            color: $text-light;
            margin-right: 60px;
            span {
                color: $text-dark;
            }
        }
        P[other='price'] {
            margin-top: 1rem;
            margin-bottom: 0;
        }

        P[other='days'] {
            margin-top: 0.75rem;
        }

        P[other='error-text'] {
            text-align: center;
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: $mobile) {
    .doctor-video-call-card-container {
        .book-video-container {
            .list-of-particular-days-container {
                .particular-day-container {
                    width: 31%;
                }
            }
        }
    }
}
