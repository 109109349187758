@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';
@import '../../styles/fontSizes.scss';

.book-video-container {
    width: 100%;

    P[other='set-date-title'] {
        margin-bottom: 1rem;
        margin-top: 2rem;
        text-align: right;
        color: $text-dark;
    }

    &.center {
        P[other='set-date-title'] {
            text-align: center;
        }
    }

    .list-of-particular-days-container {
        // border: solid;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        .particular-day-container {
            width: 115px;
            height: 41px;
            margin-right: 6px;
            margin-bottom: 0.5rem;
            padding: 8px 9px;
            background: #f4fcfd;
            border: 1px solid #c0edf6;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: space-around;
            .icon {
                margin-left: 0.5rem;
                width: 16px;
                height: 16px;
            }
            span {
                margin-bottom: 0;
                font-size: $p2;
                color: $text-dark;
                line-height: 1;
                padding-top: 5px;
            }

            &:hover {
                background-color: #e6e6e6;
            }

            &.active {
                background: $primary;
                span {
                    color: $bg;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .book-video-container {
        .list-of-particular-days-container {
            justify-content: center;
            .particular-day-container {
                width: 40%;
            }
        }
    }
}
