@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.nav-bar-container {
    height: 80px;
    z-index: 3;
    direction: rtl;

    .navbar {
        height: 76px;
        width: 90%;
        padding-top: 20px;
        margin: auto;
        max-width: $max-content-width;

        .brand-container {
            &:hover {
                text-decoration: none;
                color: inherit;
            }
        }

        .toggle-btn {
            &:focus {
                box-shadow: none;
            }
        }

        .navbar-collapse {
            .auth-container {
                width: 136px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .auth-icon-container {
                    width: 41px;
                    height: 41px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    .auth-icon {
                        width: 22px;
                        fill: $primary;
                    }

                    &.active {
                        background-color: $primary;
                        .auth-icon {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .nav-bar-container {
        .navbar {
            width: 98% !important;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .nav-bar-container {
        height: auto;
        .navbar {
            height: auto;
            background-color: transparent;

            .navbar-collapse {
                .auth-container {
                    justify-content: center;
                    margin: auto;
                }

                &.white {
                    color: #00000031;
                    button {
                        color: $primary;
                        border-color: $primary;

                        &:hover {
                            background-color: $primary;
                            color: white;
                        }
                    }
                }
            }
        }

        &.login-nav {
            position: absolute;
            width: 100%;

            .navbar-collapse {
                // border: solid;
                box-shadow: 0px 0px 30px #00000031;
                background-color: $bg;
                padding: 1rem;
                border-radius: 4px;
            }
        }
        &.out {
            background-color: #ebf9fc;
        }

        &.in {
            background-color: #d5f3f9;
        }
    }
}
