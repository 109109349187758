@import '../../styles/screenSizes.scss';

.pagination-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    font-family: 'Tajawal', sans-serif !important;

    .page-guid {
        display: flex;
        align-items: center;
        p {
            font-family: 'Tajawal', sans-serif !important;
            font-weight: normal;
            font-size: 18px;
            margin: 0px 5px;
        }
        input {
            width: 10%;
            margin: 0px 5px;
            text-align: center;
        }
    }
    .move-between-pages {
        img {
            cursor: pointer;
        }
        img[disabled] {
            cursor: auto;
        }
        .next {
            margin: 0px 15px;
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .pagination-container {
        padding: 0px !important;

        .move-between-pages {
            width: 30%;
        }
    }
}
