@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';

.forget-password-container {
    @include container;
    padding-top: 0;

    .forget-password-content {
        @include list-of-content;
        width: 1600px;
        align-items: flex-start;
        .data-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 60%;
            max-width: 700px;
            H4 {
                font-weight: 400;
                margin-bottom: 1rem;
            }

            P {
                color: $text-light;
                margin-bottom: 2rem;
                text-align: right;
            }
            // input {
            //     max-width: 470px;
            // }
            button {
                margin-top: 2.5rem;
                .submit-btn {
                    background: none;
                    border: none;
                    color: white;
                }
            }
        }
        .password-img {
            width: 406px;
            width: 35%;
            max-width: 406px;
        }
    }
}

.pass-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    p {
        text-align: center;
        direction: rtl;
        font-size: 18px;
    }
}
@media screen and (max-width: $smallDesktop) {
    .forget-password-container {
        .forget-password-content {
            .data-container {
                width: 50%;
                max-width: auto;
            }
            .password-img {
                width: 35%;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .forget-password-container {
        .forget-password-content {
            .data-container {
                order: 2;
                width: 90%;
            }
            .password-img {
                width: 60%;
            }
        }
    }
}
