@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.book-landing-container {
    @include container;
    background-color: white;

    H4 {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        color: $text-light;
        font-weight: 400;
    }

    .book-landing-content {
        @include list-of-content;

        .book-img {
            width: 44%;
            max-width: 506px;
        }

        .book-cards-container {
            // border: solid red;
            width: 50%;
            max-width: 570px;

            .book-card-container {
                display: flex;
                // flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 1.25rem 1.5rem;
                margin-bottom: 1rem;
                cursor: pointer;
                background-color: $bg;
                border: 1px solid #ebf9fc;
                &:hover {
                    P {
                        color: $primary;
                    }
                }

                .icon-container {
                    width: 53px;
                    height: 53px;
                    margin-left: 1.5rem;
                    background-color: #31c2e10d;
                    border-radius: 50%;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        width: 27px;
                    }
                }

                P {
                    margin-bottom: 0;
                }
                .add-icon {
                    width: 22px;
                    margin-right: auto;
                }
            }

            .extend-container {
                // border: solid red;
                display: flex;
                flex-direction: column;
                padding: 1.5rem 0;
                P {
                    font-weight: lighter;
                    color: $text-light;
                    margin-bottom: 0.75rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        font-weight: 400;
                        color: $text-dark;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .book-landing-container {
        background-color: white;

        H4 {
            width: 80%;
        }

        .book-landing-content {
            .book-img {
                width: 80%;
                margin-bottom: 2rem;
            }

            .book-cards-container {
                width: 80%;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .book-landing-container {
        background-color: white;

        H4 {
            width: 95%;
        }

        .book-landing-content {
            .book-img {
                width: 95%;
            }

            .book-cards-container {
                width: 95%;
            }
        }
    }
}
