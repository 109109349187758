@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.doctor-service-card-container {
    border: solid;
    width: 100%;
    margin-bottom: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    border: 1px solid #c0edf6;
    border-radius: 4px;

    P[other='content'] {
        margin-top: 1rem;
        text-align: right;
        direction: rtl;
        font-size: 17px;

        &.en {
            text-align: left;
            direction: ltr;
        }
    }
    P[other='title'] {
        font-size: 17px;
        margin-bottom: 0;
        text-align: right;
        color: $text-dark;
    }

    P[other='price'] {
        font-size: 15px;
        color: $text-light;
        font-weight: lighter;
        margin-bottom: 0;
    }
    .images-card-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .images-container {
            display: flex;
        }

        .img {
            height: 4rem;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 0.5rem;
            margin-left: 0.5rem;
        }
        .rest-of-images-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            margin: 0.5rem;

            span {
                position: absolute;
                font-size: 1.5rem;
                z-index: 1;
                color: white;
                font-weight: 500;
                cursor: pointer;
                top: 33%;
                left: 88%;
            }

            img {
                filter: brightness(0.6);
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .doctor-review-card-container {
        padding: 12px;
    }
}
