@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';
@import '../../styles/fontSizes.scss';

.nav {
    display: flex;
    flex-wrap: wrap;
    direction: rtl;
    flex: 1;
    justify-content: center;

    a {
        padding: 0;
        margin-right: 1rem;
        color: $text-dark;
        font-size: $p2;
        padding: 0.5rem 1rem !important;

        &.active {
            background-color: #31c2e11f;
            color: $primary;

            border-radius: 22px;
        }

        &.white {
            color: white;
        }

        &:hover {
            text-decoration: none;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .nav {
        width: 100%;
        flex-direction: column;
        align-items: center;

        a {
            padding: 0.5rem 2rem !important;
            margin-right: 0;

            &.white {
                color: $text-dark;
            }
        }
    }
}
