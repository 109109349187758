@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.blog-container {
    @include container;
    padding-top: 0;

    .blog-content {
        width: 65%;
        max-width: 770px;
        .img-container {
            width: 100%;
            display: flex;
            // justify-content: center;
            img {
                max-width: 100%;
            }
        }

        P {
            line-height: 33px;
            margin-top: 3.5rem;

            a {
                color: #0d6efd;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        .pre-content {
            white-space: pre-wrap;
            margin-top: 3.5rem;
            font-size: 19px;
            direction: initial;
        }
    }
}

@media screen and (max-width: $tablet) {
    .blog-container {
        .blog-content {
            width: 80%;
            max-width: none;
        }
    }
}

@media screen and (max-width: $mobile) {
    .blog-container {
        .blog-content {
            width: 90%;
            max-width: none;
        }
    }
}
