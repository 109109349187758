@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';

.blogs-landing-container {
    @include container;
    .blogs-cards-container {
        @include list-of-content;
        justify-content: space-around;
    }
}

@media screen and (max-width: $mobile) {
    .blogs-landing-container {
        .view-all-btn {
            width: 80%;
        }
    }
}
