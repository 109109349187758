@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.doctor-info-card-container {
    @include doctor-profile-card-container(center);
    color: $text-dark;

    .avatar-div {
        width: 139px;
        height: 139px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .doctor-info-card-avatar {
            width: 139px;
        }
    }

    H4 {
        margin-top: 1rem;
        font-weight: 500;
    }

    P {
        img {
            width: 22px;
            margin-left: 0.5rem;
        }

        span {
            color: $text-light;
            font-weight: lighter;
        }
    }

    P[other='description'] {
        margin-top: 1rem;
        text-align: center;
    }

    .links-container {
        width: fit-content;
        margin-bottom: 1rem;
        margin: auto;

        a {
            margin: 0 0.5rem;
            display: inline-block;

            img {
                width: 2rem;
            }
        }
    }
}
