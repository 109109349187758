@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.msgs-container {
    // border: solid;
    width: 57%;
    // border-right: 1px solid #e1f7fc;
    position: relative;
    height: 500px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    margin: auto;
    // justify-content: center;
    // align-items: center;
    &.custom {
        width: 65%;
        max-width: 770px;
    }

    .no-msgs {
        margin: auto;
    }

    .msgs-title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: solid;
        padding-bottom: 1.5rem;
        padding-right: 2.25rem;

        P[other='name'] {
            text-align: right;
            margin-bottom: 0;
            // border: solid;
        }
        .close-icon {
            position: absolute;
            width: 2rem;
            left: 1rem;
            top: 0rem;
            display: none;
        }
    }

    .msgs-data {
        background-color: #f4fcfd;
        height: 458px;
        padding: 1rem 1.5rem;
        overflow-y: scroll;
    }

    .msgs-input-container {
        padding: 1rem 1.5rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: auto;
        .msg-input-file-container,
        .msg-send-container {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .msg-input-file-container {
            background-color: #31c2e11a;
            margin-left: 1rem;
            .msg-input-file {
                width: 48px;
                height: 48px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                // z-index: -1;
                cursor: pointer;
            }

            img {
                cursor: pointer;
            }
        }

        .msg-input {
            width: 81%;
            border-radius: 30px;
            padding: 0.75rem 1.5rem;

            &:focus {
                box-shadow: none;
                border-color: $primary;
            }
        }
        .msg-send-container {
            background: linear-gradient(90deg, #31c2e1 0%, #44a8de 100%);
            margin-right: 1rem;
            border: none;
        }

        .selected-imgs-container {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-wrap: wrap;
            width: 81%;
            border: 1px solid #e1f7fc;
            border-radius: 4px;

            .selected-img-container {
                // width: 75px;
                // height: 75px;
                margin: 0.25rem;
                position: relative;

                .delete-icon-container {
                    position: absolute;
                    top: -4px;
                    right: -4px;
                    cursor: pointer;
                    // background-color: white;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    // box-shadow: 0px 0px 10px #000;
                    // .delete-icon {
                    //     fill: red;
                    // }
                }
                .selected-img {
                    width: 75px;
                    height: 75px;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .msgs-container {
        width: 100%;

        &.hide {
            display: none;
        }

        .msgs-title-row {
            .close-icon {
                display: block;
            }
        }

        .msgs-input-container {
            .msg-input {
                width: 75%;
            }

            .selected-imgs-container {
                width: 75%;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .msgs-container {
        .msgs-data {
            padding: 1rem;
        }

        .msgs-input-container {
            padding: 0.5rem;

            .msg-input-file-container,
            .msg-send-container {
                width: 30px;
                height: 30px;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
            .msg-input-file-container {
                margin-left: 0.25rem;
                .msg-input-file {
                    width: 30px;
                    height: 30px;
                }
            }

            .msg-input {
                width: 80%;
            }
            .msg-send-container {
                margin-right: 0.25rem;
            }
            .selected-imgs-container {
                width: 80%;
            }
        }
    }
}
