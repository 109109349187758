@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.contact-us-clinic-landing {
    @include container;
    background: url('../../../styles/images/contactUs.png') contain no-repeat;
    min-height: auto;

    H4 {
        color: $text-light;
        font-weight: 400;

        margin-top: 3rem;
        margin-bottom: 2.5rem;
    }
}

@media screen and (max-width: $tablet) {
    .contact-us-clinic-landing {
        H4 {
            width: 80%;
        }
    }
}

@media screen and (max-width: $mobile) {
    .contact-us-clinic-landing {
        button {
            width: 80%;
        }
    }
}
