@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.chat-container {
    @include container;
    padding: 0;

    .contact-cards-container {
        display: flex;
        width: 50%;
        max-width: 570px;
        justify-content: space-between;
        .contact-card-container {
            // border: solid black;
            width: 48%;
            max-width: 270px;
            height: 158px;
            background: white;
            position: relative;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                border-radius: 50%;
                width: 69px;
                height: 69px;
                margin-bottom: 0.5rem;
            }

            // .triangle-shape {
            //     width: 0;
            //     height: 0;
            //     border-left: 25px solid transparent;
            //     border-right: 25px solid transparent;
            //     border-top: 25px solid red;

            //     position: absolute;
            //     bottom: -25px;
            //     right: 100px;
            // }

            &.active {
                box-shadow: 3px 3px 6px #0000000f;

                .triangle-shape {
                    width: 0;
                    height: 0;
                    border-left: 25px solid transparent;
                    border-right: 25px solid transparent;
                    border-top: 25px solid white;
                    // box-shadow: 3px 3px 6px #0000000f;

                    position: absolute;
                    bottom: -25px;
                    right: 100px;
                }
            }
        }
    }

    .search-container {
        width: 400px;
        height: 48px;

        position: relative;
        border: 1px solid #f0fbfd;
        border-radius: 3px;

        display: flex;
        align-items: center;
        input {
            padding-left: 45px;
            width: 100%;
            height: 95%;
            border: none;

            &:focus {
                box-shadow: none;
            }
        }
        .icon-container {
            width: 33px;
            height: 33px;
            padding: 5px;
            margin: 5px;
            position: absolute;
            left: 0;

            border-radius: 3px;

            background: $primary;

            img {
                width: 18px;
            }
        }
    }

    .chat-content {
        @include list-of-content;
        align-items: flex-start;
        margin-top: -1.5rem;
        padding-top: 1rem;
        // border: solid;
        background-color: white;
        border: 1px solid #e1f7fc;

        &.custom {
            background-color: transparent;
            border: none;
            justify-content: center;
        }
    }
}
.confirmation-modal-container {
    // border: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;

    .btns-row {
        display: flex;
        // margin-top: auto;
        margin-top: 1rem;
        button {
            margin: 0 0.5rem;
        }
    }
}

@media screen and (max-width: $tablet) {
    .chat-container {
        .search-container {
            width: 90%;
        }
    }
}
