@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.doctor-messages-card {
    @include doctor-profile-card-container;
    .content {
        @include doctor-profile-card-content;

        P {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            color: $text-dark;
        }
    }
}
