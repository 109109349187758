@import '../../../styles/colors.scss';
.medical-record-drugs-card-container {
    // border: solid;
    margin-top: 2rem;
    padding: 2rem;
    background-color: #f4fcfd;
    position: relative;

    P[other='title'] {
        font-weight: lighter;
        text-align: right;
    }
    .first-row {
        width: 100%;
        display: flex;
        margin-top: 1rem;
        .drug-data {
            text-align: right;
            P[other='drug-name'] {
                font-weight: lighter;
                color: $text-dark;
            }
            P[other='drug-type'] {
                margin-bottom: 0;
                font-weight: lighter;
                color: $text-light;
            }
            padding-bottom: 1rem;
        }

        P[other='drug-frequency'] {
            //  border: solid;
            margin-right: auto;
            margin-bottom: 0;
            color: $text-light;

            // color: $text-light;
        }
    }

    .icon-container {
        width: 40px;
        height: 40px;
        background: $primary;
        border: 1px solid $border;
        border-radius: 50%;
        position: absolute;
        top: -1.5rem;
        right: 2rem;
        box-shadow: 3px 3px 20px #0000000d;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 15px;
        }
    }

    .images-container {
        // border: solid;
        display: flex;
        flex-wrap: wrap;
        img {
            width: 135px;
            height: 135px;
            box-shadow: 0px 0px 3px #0000000f;
            border-radius: 3px;
            margin: 0.5rem;
        }
    }
}
