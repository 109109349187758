@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';

.past-transactions-container {
    @include list-of-content;
    display: block;
    margin-top: 16px;
    padding: 32px 24px;

    h3 {
        align-self: flex-end;
        color: #434343;
        font-size: 22px;
        font-weight: 400;
        text-align: right;
    }
    .past-transactions-content {
        width: 40%;
        max-width: 570px;
        padding: 32px 0;

        align-self: flex-end;
    }
}

@media screen and (max-width: $smallDesktop) {
    .past-transactions-container {
        .past-transactions-content {
            width: 50%;
        }
    }
}

@media screen and (max-width: $tablet) {
    .past-transactions-container {
        h3 {
            align-self: center;
        }
        .past-transactions-content {
            width: 100%;

            align-self: center;
        }
    }
}

@media screen and (max-width: $mobile) {
    .past-transactions-container {
        max-width: 100%;
    }
}
