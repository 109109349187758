@import '../../styles/mixins.scss';

.blogs-container {
    @include container;
    padding-top: 0;
    padding-bottom: 0;

    .blogs-content {
        @include list-of-content;
        justify-content: space-evenly;
        margin-top: 0;

        .no-blogs {
            width: 100%;
            margin: 0;
            font-weight: 400;
        }
    }
}
