@import '../../../styles/screenSizes.scss';

.specialization-container {
    width: 190px;
    height: 190px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-color: #f4fcfd;

    .specialization-title {
        margin-top: 12px;
        // font-size: 22px;
        font-weight: 400;
        color: #434343;
    }

    &:hover {
        transform: scale(1.1);
    }
}

@media screen and (max-width: $smallDesktop) {
    .specialization-container {
        width: 150px;
        height: 150px;
        .specialization-title {
            margin-top: 12px;
            // font-size: 18px;
        }
    }
}

@media screen and (max-width: $tablet) {
    .specialization-container {
        width: 190px;
        height: 190px;
        margin-top: 7%;
        margin: 4% 2%;

        .specialization-title {
            margin-top: 12px;
            // font-size: 22px;
        }

        &:last-child {
            display: none;
        }
    }
}

@media screen and (max-width: $mobile) {
    .specialization-container {
        width: 170px;
        height: 170px;

        .specialization-title {
            margin-top: 12px;
            // font-size: 22px;
        }
    }
}
