@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.notification-card-container {
    padding: 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid $border;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .icon {
        margin-left: 1rem;
        padding: 12px;
        border-radius: 50%;

        &.notify,
        &.chat {
            background-color: #31c2e10d;
        }

        &.cancel {
            background-color: #fc48540d;
        }
        &.confirm {
            background-color: #31e1890d;
        }
    }

    .data {
        text-align: right;

        P {
            margin-bottom: 0.25rem;
        }

        P[other='title'] {
            color: $text-dark;
        }
        P[other='description'] {
            color: $text-light;
        }
        P[other='time'] {
            color: $text-dark;
            font-weight: lighter;
            margin-bottom: 0;
        }
    }
}
