@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.appointments-data {
    width: 100%;
    @include list-of-content;
    flex-direction: column;
    .title-container {
        align-self: flex-start;
        text-align: right;
        display: flex;
        align-items: flex-start;

        H4 {
            margin-bottom: 0;
            font-weight: 400;
        }

        .icon {
            width: 15px;
            margin-left: 1.25rem;
        }
    }
    .list-of-appointments-cards {
        @include list-of-content;
    }
    .pagination-container {
        width: 75rem;
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: $smallDesktop) {
    .appointments-data {
        .list-of-appointments-cards {
            justify-content: center;
        }
        .pagination-container {
            width: 100%;
        }
    }
}
