@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.download-app-container {
    width: 100%;
    background: url('../../../styles/images/Group\ 2224.svg') right no-repeat padding-box, #f8fefe;

    .download-app-content {
        display: flex;
        direction: rtl;
        margin: auto;
        max-width: $max-content-width;
        min-height: 800px;
        justify-content: space-between;

        .texts-container {
            width: 50%;
            max-width: 570px;
            padding-right: 7.5%;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .decoration {
                width: 71px;
            }

            H2 {
                color: $secondary;
                font-weight: 400;
                margin-top: 2rem;
                margin-bottom: 0.25rem;
            }
            hr {
                width: 85px;
                height: 3px;
                color: $secondary;
                margin: 0;
                margin-bottom: 3rem;
            }
            H3 {
                font-weight: 400;
                text-align: right;
                line-height: 50px;
            }

            .btns-container {
                display: flex;

                .btn-container {
                    width: 180px;
                    height: 55px;
                    margin-top: 2.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #31c2e1;
                    border-radius: 4px;
                    cursor: pointer;
                    font-family: 'Open Sans', sans-serif;

                    &:first-child {
                        margin-left: 1rem;
                    }

                    .text-container {
                        margin-left: 0.75rem;
                        text-align: left;
                        p {
                            margin-bottom: 0;
                        }

                        .small {
                            font-size: 12px;
                            line-height: 17px;
                        }

                        .title {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }

                    .icon {
                        fill: $primary;
                    }

                    &:hover {
                        background: transparent linear-gradient(270deg, #44a8de 0%, #31c2e1 100%) 0% 0% no-repeat padding-box !important;

                        p {
                            color: white;
                        }

                        .icon {
                            fill: white;
                        }
                    }
                }
            }
        }
        .preview-container {
            width: 41%;
            display: flex;
            align-items: center;
            justify-content: center;
            .preview-content {
                width: 100%;
                position: relative;
                background: url('../../../styles/images/Group\ 2203.svg') 0% 0% no-repeat padding-box;

                background-size: contain;
                background-position: center;

                display: flex;
                justify-content: center;

                .mockup {
                    width: 540px;
                    height: auto;
                    max-height: 592px;
                }
            }
        }

        &.ltr {
            .texts-container {
                padding-right: 0;
                padding-left: 7.5%;

                .decoration {
                    transform: scale(-1, 1);
                }
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .download-app-container {
        .download-app-content {
            .texts-container {
                width: 55%;
                H3 {
                    width: 100%;
                }
            }
            .preview-container {
                width: 41%;
                .preview-content {
                    .top {
                        left: 85%;
                    }
                    .mockup {
                        width: 450px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .download-app-container {
        .download-app-content {
            flex-direction: column;
            justify-content: center;

            .texts-container {
                width: 100%;
                max-width: none;
                order: 2;
                padding-right: 0;
                align-items: center;
                H3 {
                    text-align: center;
                    width: 80%;
                    align-self: center;
                }
            }
            .preview-container {
                width: 100%;
                order: 1;
                .preview-content {
                    width: 100%;
                    .top {
                        left: 75%;
                    }
                    .bottom {
                        left: 20%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .download-app-container {
        .download-app-content {
            .texts-container {
                width: 100%;

                H3 {
                    width: 90%;
                }

                .btns-container {
                    flex-wrap: wrap;
                    justify-content: center;
                    a {
                        width: 100%;
                        .btn-container {
                            width: 100%;
                            margin-top: 1rem;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
