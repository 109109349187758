@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.notifications-container {
    @include container;
    padding-top: 0;

    .notifications-content {
        @include list-of-content;
        align-items: flex-start;
        .list-of-notifications {
            width: 48%;
        }

        .notifications-img {
            width: 42%;
        }

        .pagination-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            padding: 0px 10%;
            .page-guid {
                display: flex;
                align-items: center;
                p {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: normal;
                    font-size: 18x;
                    margin: 0px 5px;
                }
                input {
                    width: 10%;
                    margin: 0px 5px;
                    text-align: center;
                }
            }
            .move-between-pages {
                img {
                    cursor: pointer;
                }
                img[disabled] {
                    cursor: auto;
                }
                .next {
                    margin: 0px 15px;
                    transform: rotate(180deg);
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .notifications-container {
        .notifications-content {
            .list-of-notifications {
                width: 90%;
                order: 2;
                margin-top: 2rem;
                .pagination-container {
                    padding: 0px;
                    justify-content: space-around;
                }
            }

            .notifications-img {
                order: 1;
                width: 80%;
            }
        }
    }
}
@media screen and (max-width: $tablet) {
    .notifications-container {
        .notifications-content {
            .list-of-notifications {
                width: 100%;
                order: 2;
                margin-top: 2rem;
                .pagination-container {
                    padding: 0px;
                    justify-content: space-around;
                }
            }
        }
    }
}
