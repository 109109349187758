@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.input-ltr {
    direction: ltr;
}

.form-container {
    width: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 24px;

    .avatar-container {
        position: relative;
        .avatar {
            width: 200px;
            height: 200px;
            border-radius: 50%;
        }

        .change-avatar-container,
        input {
            position: absolute;
            bottom: 0;
            width: 48px;
            height: 48px;
            right: 0;
            cursor: pointer;
        }

        .change-avatar-container {
            border-radius: 50%;
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 0px 4px #0000000f;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            .change-avatar {
                width: 22px;
                height: 22px;
            }
        }
        input {
            opacity: 0;
        }
    }

    input {
        margin-bottom: 0.5rem;
    }

    H4 {
        margin-top: 1.25rem;
        margin-bottom: 2rem;

        font-weight: 400;
        color: $text-dark;
    }

    H4[other='title'] {
        margin-bottom: 1.5rem;
    }
    .inputs-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .input-container {
        width: 100%;
        position: relative;
        input {
            z-index: 4;
        }

        .overlay {
            // border: solid;
            width: 100%;
            position: absolute;
            top: 0;
            height: 75%;
            z-index: 5;
            cursor: pointer;
        }
        // button {
        //     position: absolute;
        //     left: 2%;
        //     top: 15%;
        // }
    }

    .change-password {
        cursor: pointer;
    }

    P {
        margin-bottom: 1rem;
        color: $text-dark;
    }

    P[other='change-password'] {
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 2rem;
        color: $primary;
        text-align: right;

        a {
            color: inherit;
            &:hover {
                text-decoration: none;
            }
        }
    }

    P[other='error-text'] {
        text-align: right;
        width: 100%;
        color: #fc4854;
        font-weight: lighter;
    }

    .gender-container {
        width: 100%;
        margin-bottom: 1.7rem;

        P[other='title']::after {
            content: '*';
            color: #fc4854;
        }
        P {
            text-align: right;
        }

        .btns-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            button {
                width: 100%;
                height: 45px;
                margin: 0 5px;
                min-width: fit-content;
            }
        }
    }

    .check-box-container {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        align-self: flex-start;

        input {
            width: 20px;
            height: 20px;
            margin-bottom: 0;

            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            box-shadow: none;
            text-align: center;
            border: 1px solid $text-light;
            cursor: pointer;
        }
        input[type='checkbox']:checked {
            background: $primary;
            border: none;
        }
        input[type='checkbox']:checked:after {
            content: '✔';
            color: white;
            line-height: 1.8;
        }
        P {
            font-size: 17px;
            font-family: 'Open Sans', sans-serif;
            font-weight: normal;
            margin: 0px 5px;
            color: #0090b5;

            button {
                color: #0090b5;
                background-color: white;
                border: none;
                text-decoration: #0090b5;
                margin: 0px 7px;
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .form-container {
        width: 100%;
        .gender-container {
            padding: 0;
        }
    }
}

@media screen and (max-width: $tablet) {
    .form-container {
        align-items: center;

        .gender-container {
            width: 100%;
            margin-bottom: 1.7rem;

            P {
                text-align: right;
            }

            .btns-container {
                justify-content: center !important;

                button {
                    height: 45px;
                    margin: 0 5px;
                    min-width: auto;
                }
            }
        }
    }
}
