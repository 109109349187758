@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.contact-us-container {
    @include list-of-content;
    max-width: 100%;
    margin-top: 2rem;
    .contact-us-img {
        width: 40%;
    }

    .form {
        width: 40%;
        text-align: right;

        .inputs-container {
            display: flex;
            justify-content: space-between;
        }
        div {
            margin-bottom: 0.25rem;
        }
        P[other='error'] {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .contact-us-container {
        .contact-us-img {
            width: 40%;
        }

        .form {
            width: 50%;
        }
    }
}

@media screen and (max-width: $tablet) {
    .contact-us-container {
        .contact-us-img {
            width: 70%;
            order: 1;
            margin-top: 5%;
            margin-bottom: 5%;
        }
        .form {
            width: 90%;
            order: 2;
        }
    }
}
