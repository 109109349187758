@import '../../../styles/screenSizes.scss';
.react-calendar {
    width: 100%;
    margin-bottom: 16px;
    // padding: 12px 12px;
    background: #f4fcfd;
    border: 1px solid #c0edf6;
    border-radius: 3px;
    font-family: 'Tajawal', sans-serif !important;

    .react-calendar__tile {
        abbr {
            color: #484848;
            // font-size: 15px;
        }
    }

    // .react-calendar__tile--now {
    //     background: red;
    // }
    .react-calendar__navigation {
        width: 50%;
        margin: auto;
        margin-top: 16px;
        margin-bottom: 12px;
    }
    .react-calendar__tile:disabled {
        // background-color: #f0f0f0;
        abbr {
            color: #c4c4c4;
        }
    }

    .react-calendar__tile--active {
        background: #31c2e1;
        abbr {
            color: white;
        }
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #31c2e1;
    }

    .react-calendar__month-view__weekdays__weekday {
        abbr {
            color: #808080;
            font-weight: 400;
            font-size: 16px;
            text-decoration: none;
        }
    }

    .react-calendar__viewContainer {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: $tablet) {
    .react-calendar {
        width: 80%;
        align-self: center;
        margin: auto;
        margin-bottom: 16px;

        .react-calendar__month-view__weekdays__weekday {
            padding: 0.5em 0;
            abbr {
                font-size: 13px;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .react-calendar {
        width: 100%;
    }
}
