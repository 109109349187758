@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.iframe {
    display: block;
}

.payment-confirm-container {
    @include container;
    padding-top: 0;

    .payment-content {
        @include list-of-content;

        .data-container {
            width: 48%;
        }

        .image {
            width: 43%;
            max-width: 511px;
        }
    }

    P[other='code'] {
        font-weight: 700;
        font-size: 2rem;
    }

    .modal-content {
        border: solid;
    }
}

@media screen and (max-width: $tablet) {
    .payment-confirm-container {
        @include container;
        padding-top: 0;

        .payment-content {
            @include list-of-content;

            .data-container {
                width: 90%;
                order: 2;
            }

            .image {
                width: 80%;
                max-width: 511px;
                order: 1;
            }
        }
    }
}

.recharge-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    // border: solid;
}
