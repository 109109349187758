@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.container {
    width: 90%;
    max-width: $max-content-width;
    margin: 1rem auto;
    direction: ltr;
    text-align: left;
}
