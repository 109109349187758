@import '../../../styles/colors.scss';

.card-title-container {
    display: flex;
    align-items: center;
    .icon-container {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #31c2e10d;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            width: 22px;
        }
    }

    P {
        margin-right: 0.75rem;
        margin-bottom: 0;
        color: $text-dark;
    }
}
