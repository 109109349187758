@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/screenSizes.scss';

.appointment-card-container {
    border: 1px solid $border;
    width: 49%;
    margin-bottom: 1rem;
    padding: 24px;
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    background-color: white;

    img {
        width: 66px;
        height: 66px;
        border-radius: 50%;
    }

    .data {
        margin-right: 1rem;
        text-align: right;

        P[other='dr-name'] {
            margin-bottom: 2px;
        }

        P[other='dr-level'] {
            font-weight: lighter;
            margin-bottom: 1rem;
        }

        P[other='name'],
        P[other='date'],
        P[other='price'] {
            font-weight: lighter;

            span {
                font-weight: 400;
                margin-right: 0.75rem;
            }
        }

        .type-container {
            display: flex;
            align-items: center;
            // border: solid;
            P {
                margin-bottom: 0;
                color: $text-dark;
            }
            .icon-container {
                width: 32px;
                height: 32px;
                margin-left: 0.5rem;
                background-color: $border;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                img {
                    width: 16px;
                    height: 16px;
                    border-radius: 0;
                }
            }
        }
    }

    .btn {
        align-self: flex-end;
        margin-right: auto;

        &.not-clickable {
            button {
                cursor: default;
            }
        }
    }

    .actions-container,
    .finished-container {
        margin-right: auto;
        align-self: flex-end;
    }

    .actions-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        div {
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            P[other='text'] {
                display: none;
                color: white;
                margin-bottom: 0;
            }
            img {
                width: 18px;
                height: 18px;
            }

            &:hover {
                width: 148px;

                border-radius: 20px;

                -webkit-transition: width 700ms ease;
                -moz-transition: width 700ms ease;
                -ms-transition: width 700ms ease;
                -o-transition: width 700ms ease;
                transition: width 700ms ease;

                P[other='text'] {
                    display: block;
                }

                img {
                    margin-left: 0.75rem;
                }
            }
        }

        .cancel-container {
            background: transparent linear-gradient(90deg, #fc485499 0%, #fc4854 100%) 0% 0% no-repeat padding-box;
            border-radius: 50%;
            margin-bottom: 0.5rem;
        }
        .delay-container {
            background: transparent linear-gradient(270deg, #31c2e1 0%, #31c2e199 100%) 0% 0% no-repeat padding-box;
            border-radius: 50%;
        }
    }

    .finished-container {
        border-right: 1px solid #e9e9e9;
        padding-right: 3rem;
        align-self: stretch;

        padding-top: 1rem;

        P {
            margin-bottom: 0;
        }

        P[other='date'] {
            margin-bottom: 1rem;
        }
    }
}

.appoint-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
        direction: rtl;
        font-size: 18px;
    }
}

@media screen and (max-width: $smallDesktop) {
    .appointment-card-container {
        width: 70%;
    }
}

@media screen and (max-width: $tablet) {
    .appointment-card-container {
        width: 100%;
    }
}

@media screen and (max-width: $mobile) {
    .appointment-card-container {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;

        .data {
            align-self: center;
            margin-top: 1rem;
        }

        .btn,
        .finished-container,
        .actions-container {
            margin-top: 1rem;
        }

        .finished-container {
            border-top: 1px solid #e9e9e9;
            border-right: none;
            padding-right: 0;
            width: 100%;
        }
    }
}
