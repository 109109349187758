@import '../../../styles/colors.scss';
.current-state-container {
    // border: solid;
    display: flex;

    .dr-data {
        text-align: right;
        P[other='dr-name'] {
            margin-bottom: 0;
        }
        P[other='dr-level'] {
            // margin-bottom: 0;
            font-weight: lighter;
            margin-bottom: 0;
        }
        padding-bottom: 1rem;
    }

    P[other='date'] {
        //  border: solid;
        margin-right: auto;
        margin-bottom: 0;
        font-weight: lighter;
        color: $text-light;
    }
}
