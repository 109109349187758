@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.medical-record-card-container {
    border: solid;
    margin-bottom: 3rem;
    padding: 2rem;
    padding-top: 2rem;
    // display: flex;
    // flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    background: white;

    border: 1px solid $border;
    border-radius: 4px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
    .first-row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #e9e9e9;
        margin-bottom: 1rem;
        .dr-data {
            P[other='dr-name'] {
                margin-bottom: 0;
            }
            P[other='dr-level'] {
                // margin-bottom: 0;
                font-weight: lighter;
                margin-bottom: 0;
            }
            padding-bottom: 1rem;
        }

        P[other='date'] {
            //  border: solid;
            margin-right: auto;
            margin-bottom: 0;
            font-weight: lighter;
            color: $text-light;
        }
    }

    .icon-container {
        width: 58px;
        height: 58px;
        background: #ffffff;
        border: 1px solid $border;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        right: 2rem;
        box-shadow: 3px 3px 20px #0000000d;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:first-of-type {
        // border: solid;
        // margin-right: 5px;
        position: relative;
        right: 2rem;
        box-shadow: 3px 3px 20px #0000000d;
    }
}

@media screen and (max-width: $tablet) {
    .medical-record-card-container {
        &:first-of-type {
            right: 0;
            bottom: 1rem;
        }
    }
}
