@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.wallet-action-container {
    background-color: white;
    border-radius: 0.25rem;
    // border: solid;

    P {
        margin-bottom: 1.5rem;
        text-align: right;
        color: $text-dark;
    }

    P[other='replace-promo'] {
        color: $text-light;
        margin-top: 0.5rem;
    }

    P[other='error-text'] {
        color: red;
        margin-bottom: 0;
    }
    input {
        text-align: right;
        font-size: $p2;
    }

    button {
        margin-top: 2rem;
    }
}
