@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.msg-container {
    width: fit-content;
    max-width: 66%;
    text-align: right;

    .msg-content {
        padding: 0.75rem 1.25rem;
        P[other='content'] {
            margin-bottom: 0;
            margin-top: 0.5rem;

            a {
                text-decoration: underline !important;
            }
        }

        .image {
            width: 180px;
            height: 180px;
            margin: 0.25rem;
            border-radius: 0.25rem;
            cursor: pointer;
        }
    }
    P[other='time'] {
        color: $text-light;
        margin-top: 0.25rem;
        font-weight: lighter;
    }

    &.sender {
        .msg-content {
            border-radius: 25px 25px 0px 25px;
            background-color: $primary;
            color: white;
        }
        P[other='time'] {
            text-align: right;
        }
    }
    &.receiver {
        margin-right: auto;
        .msg-content {
            border-radius: 25px 25px 25px 0px;
            background-color: #e5f6f9;
            color: $text-dark;
        }
        P[other='time'] {
            text-align: left;
        }
    }
}

@media screen and (max-width: $mobile) {
    .msg-container {
        max-width: 85%;
        .msg-content {
            padding: 0.75rem;
        }
    }
}
