@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.medical-record-images-card-container {
    // border: solid;
    margin-top: 2rem;
    padding: 2rem;
    background-color: #f4fcfd;
    position: relative;
    .title-row {
        width: 100%;
        display: flex;
        .title-data {
            text-align: right;
            P[other='title'] {
                font-weight: lighter;
            }
            P[other='sub-header'] {
                margin-bottom: 0;
            }
            padding-bottom: 1rem;
        }

        P[other='date'] {
            margin-right: auto;
            margin-bottom: 0;
            font-weight: lighter;
        }

        .btn-container {
            // border: solid;
            margin-right: auto;
            .upload-icon {
                fill: $primary;
            }

            button:hover {
                .upload-icon {
                    fill: white;
                }
            }
        }
    }

    .item {
        text-align: right;
    }

    .icon-container {
        width: 40px;
        height: 40px;
        background: $primary;
        border: 1px solid $border;
        border-radius: 50%;
        position: absolute;
        top: -1.5rem;
        right: 2rem;
        box-shadow: 3px 3px 20px #0000000d;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
        }
    }

    .images-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .image {
            height: 5rem;
            border-radius: 3px;
            cursor: pointer;
            margin: 0 0 0.5rem 0.5rem;
        }
    }

    .rest-of-images-container {
        // border: solid;
        display: flex;
        align-items: center;
        justify-items: center;
        position: relative;
        margin: 0.5rem;
        span {
            position: absolute;
            /* align-self: center; */
            left: 50px;
            top: 50px;
            font-size: 2.25rem;
            z-index: 1;
            color: white;
            font-weight: 500;
            cursor: pointer;
        }

        img {
            // filter: brightness(0.6);
        }
    }
}

@media screen and (max-width: $mobile) {
    .medical-record-images-card-container {
        padding-bottom: 5rem;
        .title-row {
            .btn-container {
                position: absolute;
                bottom: 0;
                margin-bottom: 1rem;
                width: 80%;
                padding: 0 1rem;
            }
        }
        .images-container {
            justify-content: center;
        }
    }
}
