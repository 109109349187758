@import '../../styles/mixins.scss';
@import '../../styles/fontSizes.scss';
@import '../../styles/screenSizes.scss';

.error-container {
    @include container;

    background: url('../../styles/images/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    position: relative;

    height: 100vh;
    margin-top: -80px;
    max-width: 100vw;
    overflow-x: hidden;
    text-align: center;
    // direction: ltr;

    .text-container {
        min-width: 50%;
        position: absolute;
        top: 73%;
        max-width: 90%;
        H4 {
            font-weight: 500;
            color: white;
        }

        pre {
            font-size: $title;
            font-weight: 400;
            color: white;
            white-space: pre-wrap;
            overflow-x: hidden;
        }

        button {
            min-width: 170px;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .error-container {
        margin-top: 0;

        .text-container {
            button {
                width: 170px;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .error-container {
        .text-container {
            button {
                width: 40%;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .error-container {
        .text-container {
            top: 62%;
            button {
                width: 70%;
            }
        }
    }
}
