@import '../../styles/colors.scss';
.saved-card-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &.active {
        box-shadow: 3px 3px 10px 1px #ccc;
        padding: 0 0.5rem;
        border-radius: 0.25rem;
    }
    .data-content {
        text-align: left;
        margin-right: 1.5rem;
        P {
            margin-bottom: 0;
            color: $text-dark;
        }
        P[other='type'] {
            font-weight: lighter;
        }
    }

    .trash-container {
        // border: solid;
        width: 38px;
        height: 38px;
        // background-color: red;
        background: transparent linear-gradient(90deg, #fc485499 0%, #fc4854 100%) 0% 0% no-repeat padding-box;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        cursor: pointer;
        .trash-icon {
            width: 14px;
            fill: white;
        }
        P[other='text'] {
            display: none;
            color: white;
            margin-bottom: 0;
        }

        &.hover {
            cursor: pointer;

            :hover {
                width: 148px;

                border-radius: 20px;

                -webkit-transition: width 700ms ease;
                -moz-transition: width 700ms ease;
                -ms-transition: width 700ms ease;
                -o-transition: width 700ms ease;
                transition: width 700ms ease;

                P[other='text'] {
                    display: block;
                }

                .trash-icon {
                    margin-left: 0.75rem;
                }
            }
        }
    }

    .check-icon {
        width: 1.5rem;
        height: 1.5rem;
        // margin-left: auto;
        margin-right: auto;
        margin-left: 1rem;
    }
}
