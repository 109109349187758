@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.hero-container {
    min-height: 550px;
    width: 100%;
    background: transparent
        linear-gradient(180deg, #c0edf6 0%, #f0fbfd 83%, #ffffff 95%, #ffffff 100%) 0% 0% no-repeat
        padding-box !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    position: relative;

    .hero-title {
        width: 50%;
        padding-right: 7.25%;
        font-weight: 400;
        text-align: right;
        color: $text-dark;
        position: absolute;

        H1 {
            font-weight: 400;
        }

        H1[selector='h3'] {
            color: $text-light;
        }
    }
    .hero-img {
        width: 60%;
        margin-right: auto;
    }
}

@media screen and (max-width: $smallDesktop) {
    .hero-container {
        justify-content: center;
        min-height: 600px;

        .hero-title {
            width: 70%;
            padding-right: 0;
            margin-top: 30px;

            order: 2;
            text-align: center;

            position: relative;
        }
        .hero-img {
            width: 70%;
            order: 1;
            margin: auto;
        }
    }
}

@media screen and (max-width: $tablet) {
    .hero-container {
        .hero-title {
            width: 95%;
        }
        .hero-img {
            width: 95%;
            order: 1;
        }
    }
}

@media screen and (max-width: $mobile) {
    .hero-container {
        min-height: 500px;
        .hero-title {
            width: 90%;
        }
        .hero-img {
            width: 100%;
        }
    }
}
