@import '../../../styles/colors.scss';
.rc-slider-track {
    background-color: $primary;
}

.rc-slider-handle {
    width: 16px;
    height: 16px;
    margin-top: -6px;
    border: solid 2px $primary;
}
