@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.download-app-container {
    @include container(row, stretch);
    min-height: 800px;
    direction: rtl;
    justify-content: space-between;

    .texts-container {
        width: 55%;
        padding-right: 7.5%;

        background: url('../../../styles/images/Group\ 2224.svg') 0% 0% no-repeat padding-box;

        display: flex;
        flex-direction: column;
        justify-content: center;
        .decoration {
            width: 71px;
        }
        H3 {
            margin-top: 33px;
            width: 80%;
            font-weight: 400;
            text-align: right;
            color: $text-dark;
        }
    }
    .preview-container {
        width: 41%;
        display: flex;
        align-items: center;
        justify-content: center;
        .preview-content {
            width: 540px;
            position: relative;

            background: url('../../../styles/images/Group\ 2203.svg') 0% 0% no-repeat padding-box;

            background-size: contain;
            background-position: center;

            display: flex;
            justify-content: center;

            .decoration2 {
                width: 33px;
            }
            .top {
                position: absolute;
                left: 87%;
                top: 15%;
            }
            .mockup {
                width: 300px;
            }
            .bottom {
                position: absolute;
                left: 9%;
                top: 80%;
            }
        }
    }

    &.ltr {
        .texts-container {
            padding-right: 0;
            padding-left: 7.5%;

            .decoration {
                transform: scale(-1, 1);
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .download-app-container {
        .texts-container {
            width: 55%;
            H3 {
                width: 100%;
            }
        }
        .preview-container {
            width: 41%;
            .preview-content {
                .top {
                    left: 85%;
                }
                .mockup {
                    width: 200px;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .download-app-container {
        justify-content: center;
        .texts-container {
            width: 90%;
            order: 2;
            padding-right: 0;
            H3 {
                text-align: center;
                // font-size: 28px;
                width: 80%;
                align-self: center;
            }
        }
        .preview-container {
            width: 100%;
            order: 1;
            .preview-content {
                width: 100%;
                .top {
                    left: 75%;
                }
                .mockup {
                    width: 200px;
                }
                .bottom {
                    left: 20%;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .download-app-container {
        .texts-container {
            H3 {
                // font-size: 26px;
                width: 100%;
            }
        }
        .preview-container {
            .preview-content {
                .top {
                    left: 80%;
                }
                .bottom {
                    left: 9%;
                }
            }
        }
    }
}
