@import '../../../styles/colors.scss';

.current-promo-container {
    margin-bottom: 1.5rem;

    text-align: right;
    border-bottom: 1px solid #e9e9e9;
    P[other='title'] {
        margin-bottom: 1rem;
        color: $text-dark;
        font-weight: 400;
    }
    P {
        margin-bottom: 1rem;
        color: $text-light;
        font-weight: lighter;
        span {
            color: $text-dark;
            font-weight: 400;
            margin-right: 0.5rem;
        }
    }
    P[other='number'] {
        margin-bottom: 1.5rem;
    }
}
