@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/screenSizes.scss';

.iframe {
    display: block;
}

.patient-profile-container {
    @include container;
    background-color: $bg;
    padding-top: 0;

    .patient-profile-content {
        @include container;
        padding-top: 0;
        padding-bottom: 0;
        background-color: $bg;

        width: 100%;

        .data {
            width: 40%;
            max-width: 522px;

            .save {
                button {
                    margin-top: 0;
                }
            }
        }

        .rest-container {
            @include list-of-content;
            // border: solid;
            align-items: flex-start;

            &.center {
                justify-content: center;
            }
            .fav-doctors-container,
            .saved-cards-container {
                width: 49%;
                padding: 32px 24px;
                background-color: white;
                border: 1px solid $border;
                border-radius: 4px;
            }

            .fav-doctors-container {
                // border: red solid;

                P[other='title'] {
                    text-align: right;
                }

                .fav-doctors-cards-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .fav-doctors-card-container {
                        margin-bottom: 0.5rem;
                        border: 1px solid $border;
                        width: 49%;
                        padding: 1.5rem;
                        border-radius: 4px;
                        cursor: pointer;

                        img {
                            width: 92px;
                            height: 92px;
                        }

                        P[other='dr-name'] {
                            color: $text-dark;
                            margin-top: 1rem;
                        }
                        P[other='level'],
                        P[other='reviews'] {
                            font-weight: lighter;
                            color: $text-dark;
                            margin-bottom: 0.5rem;

                            img {
                                width: 17px;
                                height: 16px;
                                margin-left: 0.5rem;
                            }
                        }
                        P[other='reviews'] {
                            font-weight: lighter;
                        }

                        &:hover {
                            -webkit-box-shadow: 0px 5px 11px 0px rgba(72, 72, 72, 0.1);
                            -moz-box-shadow: 0px 5px 11px 0px rgba(72, 72, 72, 0.1);
                            box-shadow: 0px 5px 11px 0px rgba(72, 72, 72, 0.1);
                        }
                    }
                }
            }
            .saved-cards-container {
                border: 1px solid $border;

                P[other='title'] {
                    text-align: right;
                }
                .saved-card-container {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1rem;
                    .data-content {
                        text-align: left;
                        margin-right: 1.5rem;
                        P {
                            margin-bottom: 0;
                            color: $text-dark;
                        }
                        P[other='type'] {
                            font-weight: lighter;
                        }
                    }

                    .trash-container {
                        width: 38px;
                        height: 38px;
                        background: transparent linear-gradient(90deg, #fc485499 0%, #fc4854 100%)
                            0% 0% no-repeat padding-box;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: auto;
                        cursor: pointer;
                        img {
                            width: 14px;
                        }
                        P[other='text'] {
                            display: none;
                            color: white;
                            margin-bottom: 0;
                        }

                        &:hover {
                            width: 148px;

                            border-radius: 20px;

                            -webkit-transition: width 700ms ease;
                            -moz-transition: width 700ms ease;
                            -ms-transition: width 700ms ease;
                            -o-transition: width 700ms ease;
                            transition: width 700ms ease;

                            P[other='text'] {
                                display: block;
                            }

                            img {
                                margin-left: 0.75rem;
                            }
                        }
                    }
                }
            }
        }

        button {
            &:last-of-type {
                margin-top: 1rem;
                min-width: 229px;
            }
        }

        button[type='lightRed'] {
            .logout-icon {
                fill: #fc4854;
                margin-left: 0.75rem;
            }
            &:hover {
                .logout-icon {
                    fill: white;
                }
            }
        }
    }
}
@media screen and (max-width: $smallDesktop) {
    .patient-profile-container {
        .patient-profile-content {
            padding: 24px;

            .data {
                width: 100%;
                max-width: 70%;
            }

            .rest-container {
                .fav-doctors-container {
                    .fav-doctors-cards-container {
                        .fav-doctors-card-container {
                            width: 100%;
                            padding: 1rem;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: $tablet) {
    .patient-profile-container {
        .patient-profile-content {
            .data {
                max-width: 90%;
            }

            .rest-container {
                .fav-doctors-container,
                .saved-cards-container {
                    width: 100%;
                    margin-bottom: 1rem;
                }

                .fav-doctors-container {
                    .fav-doctors-cards-container {
                        .fav-doctors-card-container {
                            width: 49.5%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .patient-profile-container {
        .patient-profile-content {
            .data {
                max-width: 100%;
            }
            .rest-container {
                max-width: 100%;
                .saved-cards-container {
                    padding: 1rem;
                }
                .fav-doctors-container {
                    .fav-doctors-cards-container {
                        .fav-doctors-card-container {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
