@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';

.registration-container {
    @include container;

    background: url('../../styles/images/login.png');
    height: auto !important;
    background-size: cover !important;
    background-position-x: center;
    background-position-x: center;
    background-repeat: no-repeat;
    min-height: 80vh;
    z-index: 2;
    position: relative;
    top: -10vh;
    margin-bottom: -9%;

    background-size: 100% auto;

    background-color: white;
    .registration-content {
        @include list-of-content;
        width: 1600px;
        margin-top: 10vh;

        flex-direction: column;
        align-items: flex-start;
        .data {
            width: 35%;
            min-width: 30rem;

            h4 {
                padding: 1rem 0;

                font-weight: 400;
                color: $text-dark;
            }

            .check-box-container {
                margin-top: 1rem;
                display: flex;
                align-items: baseline;
                align-self: flex-start;

                input {
                    width: 23px;
                    height: 23px;
                    margin-bottom: 0;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -o-appearance: none;
                    appearance: none;
                    box-shadow: none;
                    text-align: center;
                    border: 1px solid $text-light;
                    cursor: pointer;
                    border: 0.5px solid red;
                }
                input[type='checkbox']:checked {
                    background: $primary;
                    border: none;
                }
                input[type='checkbox']:checked:after {
                    content: '✔';
                    color: white;
                }
                P {
                    font-size: 17px;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: normal;
                    margin: 0px 5px;
                    button {
                        color: #0090b5;
                        background-color: white;
                        border: none;
                        text-decoration: #0090b5;
                        margin: 0px 7px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .registration-container {
        margin-bottom: -9%;
        height: 500px;

        top: 0;

        min-height: 60vh;
        margin-bottom: 0;

        .registration-content {
            .data {
                width: 45%;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .registration-container {
        margin-bottom: 0;

        top: 0;

        .registration-content {
            margin-top: 23vh;
            align-items: center;

            .data {
                width: 80%;
                min-width: 80%;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .registration-container {
        margin-bottom: -15px;

        background-color: #f5fdfe;

        top: -15px;

        .registration-content {
            margin-top: 18vh;

            .data {
                width: 100%;
            }
        }
    }
}
