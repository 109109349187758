@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

.medical-record-container {
    @include container;
    padding-top: 0;

    .medical-record-content {
        @include list-of-content;
        align-items: flex-start;
        background-color: $bg;

        .first-content {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
        }
    }
}
