@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.card-text-item-container {
    width: 100%;
    margin-top: 1rem;

    display: flex;
    align-items: flex-start;
    text-align: right;

    P[other='title'] {
        color: $text-light;
        font-weight: lighter;
        margin-bottom: 0;
        width: max-content;
        margin-left: 0.3rem;
    }
    .title-container {
        P[other='value'] {
            margin-right: 1rem;
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: $mobile) {
    .card-text-item-container {
        flex-direction: column;

        P[other='title'] {
            width: 100%;
        }
        .title-container {
            width: 100%;
            margin-top: 0.5rem;
            align-self: flex-end;
            P[other='value'] {
                margin-right: 0;
            }
        }
    }
}
