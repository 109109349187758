@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';
@import '../../../styles/mixins.scss';

.container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 1rem 0;
    max-width: $max-content-width;

    .card {
        width: 40%;
        height: max-content;
        border: solid;
        padding: 2rem 1.5rem;
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        background: white;

        border: 1px solid $border;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
        .dr-data {
            P[other='type'] {
                margin-bottom: 0.5rem;
                text-align: right;
            }
            P[other='date'] {
                // margin-bottom: 0;
                font-weight: lighter;
                margin-bottom: 0;
                text-align: right;
                color: $text-light;
            }
        }

        .icon-container {
            width: 40px;
            height: 40px;
            background: #31c2e114;
            border: 1px solid $border;
            border-radius: 50%;
            box-shadow: 3px 3px 20px #0000000d;

            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.75rem;

            img {
                width: 17px;
            }
        }

        &.active {
            position: relative;
            right: 2rem;
            box-shadow: 3px 3px 20px #0000000d;
        }
    }

    .details {
        width: 58%;
    }
}

@media screen and (max-width: $tablet) {
    .container {
        flex-direction: column;

        .card {
            width: 100%;

            &:first-of-type {
                right: 0;
                bottom: 1rem;
            }
        }

        .details {
            width: 100%;
        }
    }
}
