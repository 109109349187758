@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.data-item-container {
    padding: 2rem;
    margin-bottom: 1rem;
    text-align: right;
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    p[other='title'] {
        margin-bottom: 1.25rem;
        color: $text-dark;
    }
    .data-item-content {
        width: fit-content;
        .row {
            display: flex;
            margin-bottom: 1rem;

            P {
                width: 115px;
                color: $text-light;
                font-weight: lighter;
                margin-bottom: 0;
            }
            span {
                color: $text-dark;
            }

            &.total {
                P {
                    color: $text-dark;
                    font-weight: 400;
                }

                P[other='total-description'] {
                    width: auto;
                }
                span {
                    font-weight: 500;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .data-item-container {
        padding: 1.5rem;

        .data-item-content {
            .row {
                &.total {
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .data-item-container {
        .data-item-content {
            width: 90%;
        }
    }
}
