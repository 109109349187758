@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/screenSizes.scss';

.contact-us-container {
    @include container;
    padding-top: 0;
    padding-bottom: 0;
    .contact-us-content {
        @include list-of-content;
        flex-direction: column;
        align-items: flex-start;

        P[other='title'] {
            text-align: right;
            max-width: 570px;
            margin-bottom: 0;
        }
    }

    .contact-us-items-container {
        width: 100%;
        // min-height: 160px;
        padding: 3rem 0;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        background-color: #f4fcfd;

        .contact-us-item {
            display: flex;

            width: 330px;
            .icon-container {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #ebf9fc;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 1rem;
            }

            .data-container {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                P[other='title'] {
                    margin-bottom: 0.25rem;
                    color: $text-light;
                    font-weight: lighter;
                }
                P[other='data'] {
                    margin-bottom: 0;
                    color: $text-dark;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .contact-us-container {
        .contact-us-items-container {
            .contact-us-item {
                width: 90%;
                margin-bottom: 2rem;
            }
        }
    }
}
