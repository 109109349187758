@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, $bg 0%, #c0edf6 100%);
    width: 100%;
    padding: 1rem 0;
    z-index: 3;
    padding: 1rem;

    .nav-links-container-footer {
        margin-top: 1rem;
    }

    .social-logos-container {
        a {
            display: inline-block;
            margin: 0 0.5rem;

            .social-logo {
                width: 2rem;
            }
        }
    }

    .hr {
        width: 85%;
        opacity: 0.4;
        background-color: $secondary;
        margin: 1rem 0;
    }

    .curawella {
        // display: flex;
        align-items: center;

        P {
            color: $text-dark;
            // margin: 0;
            display: block;
            text-align: center;
        }

        img {
            height: 40px;
            margin: 0 1rem;
        }
    }
}
