@import '../../../styles/mixins.scss';
.clinic-card-container {
    @include doctor-profile-card-container;
    margin-bottom: 1rem;

    border: 1px solid #f0fbfd;
    .content {
        @include doctor-profile-card-content;
        button {
            margin-top: 16px;
        }

        P[other='error-text'] {
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
}
