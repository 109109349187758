@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';

.doctor-profile-container {
    @include container;
    padding-top: 0;

    .doctor-profile-content {
        width: 85%;
        max-width: 1300px;

        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: repeat(7, min-content);
        gap: 16px 30px;
        grid-template-areas:
            'div4 div1'
            'div5 div1'
            'div5 div2'
            'div5 div3'
            'div6 div3'
            '. div3'
            '. div3';

        // 'div5 div1 '
        // 'div6 div2'
        // 'div6 div3'
        // 'div6 div4'
        // 'div7 .';

        direction: ltr;
        .div {
            direction: rtl;
        }
        .div1 {
            grid-area: div1;
        }
        .div2 {
            grid-area: div2;
        }
        .div3 {
            grid-area: div3;
        }
        .div4 {
            grid-area: div4;
        }
        .div5 {
            grid-area: div5;
        }
        .div6 {
            grid-area: div6;
        }
        .div7 {
            grid-area: div7;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .doctor-profile-container {
        .doctor-profile-content {
            width: 95%;
        }
    }
}

@media screen and (max-width: $tablet) {
    .doctor-profile-container {
        .doctor-profile-content {
            width: 95%;
            // border: solid;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, min-content);
            gap: 16px 30px;
            grid-template-areas:
                'div1'
                'div2'
                'div4'
                'div5'
                'div6'
                'div3';
        }
    }
}
