@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.hero-container {
    min-height: 550px;
    width: 100%;

    background: transparent url('../../../styles/images/hero-back.svg');
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -80px;

    .hero-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
        min-height: 100vh;
        max-width: $max-content-width;

        .hero-title {
            width: 50%;
            font-weight: 400;
            text-align: right;
            color: $text-dark;
            padding-right: 3rem;

            H1,
            H2,
            H3 {
                font-weight: 400 !important;
            }

            H2 {
                color: $text-light;
                margin-bottom: 1rem;
            }

            .rating-container {
                margin-bottom: 1.5rem;
                .star-rating-container {
                    width: auto;
                }

                P {
                    width: auto;
                    margin-bottom: 0;
                }
            }

            .about {
                margin-bottom: 3rem;
                width: 100%;
            }
            .clinic-reminder {
                font-weight: 400;
            }
            .reminders-container {
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .reminder {
                    width: 100%;
                    font-weight: 400;
                }
            }

            a {
                width: auto;
            }
        }
        .hero-img {
            width: 50%;
            max-width: 569px;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .hero-container {
        justify-content: center;
        min-height: 600px;
    }
}

@media screen and (max-width: $tablet) {
    .hero-container {
        margin-top: 0;
        padding-bottom: 3rem;
        height: 91vh;

        .hero-content {
            flex-wrap: nowrap;
            height: 100%;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            .hero-title {
                width: 90%;
                margin-top: 0px;
                background-color: transparent;
                position: relative;
                padding-right: 0;
            }

            .hero-img {
                order: 1;
                margin: 2rem 0 0rem;
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .hero-container {
        .hero-title {
            width: 90%;
        }
        .hero-img {
            width: 90% !important;
        }
    }
}
