@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';

.login-container {
    @include container;

    background: url('../../styles/images/login.png');
    height: auto;
    background-repeat: no-repeat;
    min-height: 80vh;
    z-index: 1;
    position: relative;
    top: -11vh;
    margin-bottom: -9%;
    background-size: 100% auto;

    .login-content {
        @include list-of-content;
        width: 1600px;
        margin-top: 30vh;

        .form-container {
            width: 35%;
            align-self: flex-end;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            H4 {
                font-weight: 400;
                margin-bottom: 1.5rem;
            }

            button {
                margin-top: 1.5rem;
                margin-bottom: 1rem;
            }

            P[other='error-text'] {
                color: #fc4854;
            }

            .forget-password-link {
                text-align: center;
                color: $primary;
                width: 100%;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .login-container {
        margin-bottom: -9%;

        top: 0;

        min-height: 60vh;
        margin-bottom: 0;

        .login-content {
            margin-top: 20vh;

            .form-container {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .login-container {
        margin-bottom: 0;

        top: 0;

        .login-content {
            margin-top: 25vh;
            .form-container {
                width: 80%;

                align-items: center;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .login-container {
        margin-bottom: -15px;

        background-color: $bg;

        top: -15px;

        .login-content {
            .form-container {
                width: 100%;
            }
        }
    }
}
