@import '../../../styles/colors.scss';
@import '../../../styles/screenSizes.scss';

.blog-card-container {
    width: 31%;
    max-width: 370px;
    height: 414px;
    margin-bottom: 2rem;

    border: 1px solid #ebf9fc;
    // border: solid black;

    display: flex;
    flex-direction: column;

    .img-container {
        height: 50%;
        overflow: hidden;
        // border: yellow solid;

        // display: flex;
        .blog-img {
            width: 100%;
        }
    }

    .content {
        height: 50%;
        // border: brown solid;

        padding: 1.75rem 1.5rem;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        position: relative;

        .date-container {
            background-color: $border;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.4rem 1.25rem;

            position: absolute;

            top: -1.5rem;
            left: 1.25rem;
            P {
                margin-bottom: 0;
                font-weight: lighter;
            }
        }

        P[other='title'] {
            margin-bottom: 0.5rem;
        }

        P[other='description'] {
            color: $text-light;
            margin-bottom: 0.5rem;
            text-align: right;

            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow-wrap: break-word;
            //   white-space: nowrap;
        }

        a {
            color: $secondary;
            margin-bottom: 0.5rem;
        }
    }
}

@media screen and (max-width: $tablet) {
    .blog-card-container {
        width: 70%;
        max-width: none;
    }
}

@media screen and (max-width: $mobile) {
    .blog-card-container {
        width: 90%;
        max-width: none;
        height: auto;

        .img-container {
            height: 50%;
        }

        .content {
            a {
                margin-bottom: 0;

                P {
                    margin-bottom: 0;
                }
            }
        }
    }
}
