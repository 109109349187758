@import '../../styles/colors.scss';

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex: 1;
    background-color: $bg;

    .loader {
        width: 35%;
        // height: 35%;
        padding-left: 20%;
        flex: 1;
    }
}
