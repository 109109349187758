@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.past-transaction-card-container {
    // direction: rtl;
    margin-bottom: 16px;
    border: 1px solid #c0edf6;
    border-radius: 4px;

    padding: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon {
        width: 21px;
        align-self: flex-start;
        margin-left: 22px;
    }

    .past-transaction-data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: right;
        color: $text-dark;
        P[other='title'] {
            margin-bottom: 0.25rem;
        }
        P[other='dr-name'] {
            margin-bottom: 0.5rem;
        }
        P[other='date'] {
            color: $text-light;
            margin-bottom: 0;
        }
    }

    P[other='price'] {
        margin-right: auto;
        align-self: flex-start;
        color: $primary;
    }
}

@media screen and (max-width: $mobile) {
    .past-transaction-card-container {
        padding: 32px 13px;

        position: relative;

        .icon {
            position: absolute;
            top: 8%;
            right: 6%;
        }

        .past-transaction-data {
            width: 100%;
            margin: 6% 5%;
            P[other='date'] {
                margin-bottom: 0;
            }
        }

        P[other='price'] {
            position: absolute;
            bottom: 8%;
            left: 6%;
        }
    }
}
