@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.partner-container {
    width: 270px;
    height: 290px;
    padding: 32px 50px;

    background: #fbffff 0% 0% no-repeat padding-box;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    .partner-avatar {
        border-radius: 50%;
    }

    P[selector='title'] {
        font-weight: 500;
        color: $text-dark;
        margin-top: 1rem;
    }

    P[other='partner-level'] {
        font-weight: 400;
        color: $text-dark;
        margin-bottom: 0.25rem;
    }

    P[other='partner-specialization'] {
        font-weight: 400;
        color: $text-light;
        margin-bottom: 0;
    }

    .partner-social-media-container {
        //   border: solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: absolute;
        left: 85%;

        .partner-social-media-item {
            margin: 5px 0;
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .partner-container {
        margin-top: 5%;
    }
}

@media screen and (max-width: $tablet) {
    .partner-container {
        width: 48%;
        margin: 0 5px;
        margin-top: 15%;
        padding: 32px 20px;
    }
}

@media screen and (max-width: $mobile) {
    .partner-container {
        width: 80%;
        height: auto;
        margin-top: 10%;
    }
}
