@import '../../../styles/mixins.scss';
@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.wallet-hero-container {
    padding-top: 0;
    padding-bottom: 0;
    background: transparent;
    width: 100%;

    .wallet-hero-content {
        @include list-of-content;
        justify-content: center;
        .wallet-hero-img {
            width: 35%;
            margin-right: 100px;
        }

        .current-credit {
            // margin-top: 50px;
            H3[other='title'] {
                margin-bottom: 1.5rem;

                color: $text-dark;
                font-weight: 400;
            }

            H3[other='credit'] {
                color: $primary;
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .wallet-hero-container {
        .wallet-hero-content {
            flex-direction: column;
            .wallet-hero-img {
                width: 75%;
                margin-right: 0;
                order: 1;
            }
            .current-credit {
                order: 2;
            }
        }
    }
}
