@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.payment-method-container {
    width: 50%;
    // border: solid;
    min-height: 65px;

    max-width: 570px;
    margin-bottom: 1rem;

    cursor: pointer;

    -webkit-transition: transform 20s 0s ease;
    -moz-transition: transform 20s 0s ease;
    -o-transition: transform 20s 0s ease;
    transition: transform 20s 0s ease;
    // transition: transform 250ms;

    // &:hover {

    // }

    &.active,
    &:hover {
        .payment-method-content {
            justify-content: center;

            background-color: #f0fbfd;
            p {
                order: 2;
            }
            .icons-container {
                order: 1;
                margin-left: 2rem;
            }
        }
    }

    .payment-method-content {
        padding: 1.25rem 1rem;

        background-color: white;
        border: 1px solid #c0edf6;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.active {
            background-color: #f0fbfd;
        }
        p {
            margin-bottom: 0;
        }
        .icons-container {
            display: flex;
            // border: solid;

            .icon {
                margin: 0 0.25rem;
                // height: 45px;
                max-height: 45px;
            }
        }
    }

    .saved-cards-container {
        padding: 1.25rem 1rem;
        margin-top: 0.5rem;
        width: 100%;
        // border: solid;
        border-radius: 0.25rem;
        background-color: white;
        border: 1px solid #f0fbfd;

        .add-card-btn {
            margin-bottom: 1rem;
            // align-self: flex-start;
            text-align: right;
            // border: solid;
        }

        P[other='description'] {
            color: $text-light;
            margin-bottom: 2rem;
        }
    }
}

@media screen and (max-width: $tablet) {
    .payment-method-container {
        width: 80%;
    }
}
