@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';

.doctor-services-container {
    @include doctor-profile-card-container;
    border: 1px solid #f0fbfd;
    border-radius: 4px;

    p[other='error-text'] {
        margin: auto;
        margin-top: 2rem;
    }

    .list-of-services {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
