@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/screenSizes.scss';

.appointments-container {
    @include container;
    padding-top: 0;

    .appointments-content {
        @include container;
        background-color: $bg;
        .next-prev-container {
            width: 370px;
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 1.5rem;

            H4 {
                margin-bottom: 0;
                font-weight: lighter;
                color: $text-light;
                cursor: pointer;

                &[other='active'] {
                    color: $primary;
                    border-bottom: 2px solid $primary;
                    font-weight: 400;
                }
            }
        }

        .no-appointments-container {
            @include list-of-content;
            margin-top: 0;

            .no-appointments-img {
                width: 34%;
                max-width: 400px;
            }

            .no-appointments-text-container {
                // border: solid;
                text-align: right;
                H4 {
                    font-weight: 400;
                    margin-bottom: 1rem;
                }

                P {
                    margin-bottom: 3rem;
                    color: $text-light;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .appointments-container {
        .appointments-content {
            .no-appointments-container {
                .no-appointments-img {
                    width: 80%;
                    order: 1;
                }

                .no-appointments-text-container {
                    order: 2;
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .appointments-container {
        .appointments-content {
            .next-prev-container {
                width: 90%;
            }
        }
    }
}
