@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.doctor-card-container {
    // border: solid;
    max-width: 500px;
    width: 45%;
    // width: 408px;
    // max-width: 48%;
    height: 202px;
    padding: 1.5rem;
    margin-bottom: 20px;
    margin: 5px 10px;
    border: 1px solid $border;
    // border: solid;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:hover {
        text-decoration: none;
    }

    .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // border: solid;
        .avatar {
            width: 92px;
            height: 92px;
            border-radius: 50%;
        }

        .data-column {
            // border: solid;
            width: 100%;
            margin-right: 1rem;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: $text-dark;
            P {
                color: $text-dark;
            }
            P[other='level'],
            P[other='reviews'] {
                font-weight: lighter;
            }

            P[other='reviews'] {
                img {
                    width: 15px;
                }
            }
        }
    }
    .second-row {
        margin-top: 1.5rem;
        padding: 0;

        P {
            color: $text-dark;
            img {
                width: 21px;
                margin-left: 8px;
            }
        }
    }
}

@media screen and (max-width: 1365px) {
    .doctor-card-container {
        max-width: none;
        width: 70%;
    }
}

@media screen and (max-width: $smallDesktop) {
    .doctor-card-container {
        max-width: none;
        width: 90%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: $tablet) {
    .doctor-card-container {
        margin: auto;
    }
}

@media screen and (max-width: $mobile) {
    .doctor-card-container {
        width: 100%;
        padding: 24px 12px;

        .second-row {
            padding: 0;
            width: 100%;
        }
    }
}
