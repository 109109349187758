@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
@import '../../styles/screenSizes.scss';

.wallet-container {
    @include container;
    padding-top: 0;
    .actions-container {
        @include list-of-content;
        align-items: center;
        margin-top: 0;

        .action-item {
            width: 50%;
            max-width: 570px;
            padding: 32px 24px;
            background-color: white;
        }
    }

    .recharge-container {
        border: solid green !important;
    }

    .no-transactions {
        margin: 2rem 0;
    }
}
@media screen and (max-width: $tablet) {
    .wallet-container {
        .actions-container {
            .action-item {
                width: 90%;
                max-width: auto;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .wallet-container {
        .actions-container {
            .action-item {
                width: 100%;
                max-width: auto;
            }
        }
    }
}
