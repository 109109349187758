@import '../../../styles/screenSizes.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';

.doctor-about-container {
    @include doctor-profile-card-container;

    .data-container {
        margin-top: 1rem;
        padding-right: 60px;

        text-align: right;
        font-family: inherit;

        white-space: pre-line;
        word-break: keep-all;

        font-size: $p2;
        line-height: 35px;

        color: $text-dark;
    }
}

@media screen and (max-width: $tablet) {
    .doctor-about-container {
        .data-container {
            line-height: 40px;
        }
    }
}
