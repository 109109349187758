@import '../../styles/mixins.scss';
@import '../../styles/screenSizes.scss';
@import '../../styles/colors.scss';
@import '../../styles/fontSizes.scss';

.doctors-container {
    @include container;
    background: $bg;
    padding-top: 0;

    .doctors-content {
        @include list-of-content;

        width: 1600px;
        align-items: flex-start;
        .right-part {
            @include container;
            background: $bg;

            padding-top: 0;
            width: 75%;
            // border: solid;
            align-items: flex-start;
            .search-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                input,
                select {
                    margin: 0 5px;
                    border: 1px solid $border;
                    height: 48px;
                    padding-top: 10px;
                }

                .name-container {
                    width: 53%;
                    // height: 48px;

                    position: relative;
                    border: 1px solid #dfdfdf;
                    border-radius: 3px;

                    display: flex;
                    align-items: center;
                    // input {
                    //     padding-left: 45px;
                    //     width: 100%;
                    //     height: 95%;
                    //     border: none;

                    //     &:focus {
                    //         box-shadow: none;
                    //     }
                    // }
                    .icon-container {
                        width: 33px;
                        height: 33px;
                        padding: 5px;
                        margin: 5px;
                        position: absolute;
                        left: 0;

                        border-radius: 3px;

                        background: $primary;

                        img {
                            width: 18px;
                        }
                    }
                }

                select {
                    width: 21%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background: transparent;

                    background-image: url('../../styles/images/arrow.svg');
                    background-repeat: no-repeat;
                    background-position-x: 10px;
                    background-position-y: 17px;
                    background-size: 14px;
                    border: 1px solid #dfdfdf;
                }

                .filter-icon {
                    display: none;
                    cursor: pointer;
                }
            }

            .list-of-doctors {
                min-height: 500px;
                width: 100%;
                margin-top: 24px;

                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                // border: solid;
            }
        }

        .filter-container {
            width: 23%;

            position: relative;

            background: $border;
            border-radius: 4px;

            // border: solid;

            display: flex;
            flex-direction: column;
            z-index: 2;
            .filter-section {
                // border: solid;
                width: 100%;
                padding: 20px 48px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                P[other='search-title'] {
                    color: $text-dark;
                    margin-bottom: 0;
                    margin-right: 0.5rem;
                    font-weight: 500;
                }

                .btns-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    // border: solid;
                    justify-content: center;

                    button {
                        width: 120px;
                        height: 39px;
                        // max-width: 47%;
                        background-color: $bg;
                        border: none;
                        border-radius: 2px;
                        color: $text-light;
                        font-size: $p2;
                        padding: 10px;
                        margin: 5px;

                        &.active {
                            background-color: $primary;
                            color: $bg;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
            hr {
                width: 100%;
                margin: 0;
                border: 1px solid $secondary;
                opacity: 0.15;
            }

            .filter-title {
                padding: 24px;
                padding-bottom: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                    width: 16px;
                }
            }

            .filter-type {
                padding-top: 0;
                P[other='search-type'] {
                    margin-bottom: 1rem;
                    color: $text-dark;
                }
            }

            .filter-specialty {
                P[other='level'] {
                    margin-bottom: 1rem;
                    color: $text-dark;
                }

                .check-box {
                    margin-bottom: 12px;
                    display: flex;
                    input {
                        width: 14px;
                        height: 14px;
                        position: relative !important;
                        margin-left: 0 !important;

                        cursor: pointer;
                        background: transparent;
                        // color:black;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        border-radius: 2px;

                        border: 1px solid $text-light;

                        &:checked {
                            background: #31c2e1 url('../../styles//images/check.svg');
                            background-size: 10px;
                            background-repeat: no-repeat;
                            background-position: center;
                            border: none;
                        }
                    }

                    label {
                        margin-right: 8px;
                        color: $text-light;
                        font-size: $p2;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .filter-gender {
                P[other='gender'] {
                    margin-bottom: 1rem;
                    color: $text-dark;
                }
            }
            .filter-price {
                P[other='price'] {
                    margin-bottom: 1rem;
                    color: $text-dark;
                }
                .slider-values-container {
                    // border: solid;
                    margin-top: 7px;
                    width: 110%;
                    display: flex;
                    justify-content: space-between;

                    P {
                        margin-bottom: 1rem;
                        color: $text-dark;
                    }
                }
            }

            .filter-icon {
                width: 22px;
                width: 22px;
                margin-top: -6px;
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .doctors-container {
        .doctors-content {
            .right-part {
                width: 68%;

                .search-container {
                    .name-container {
                        width: 45%;
                    }

                    select {
                        width: 25%;
                        background-size: 12px;
                    }
                }
            }

            .filter-container {
                width: 30%;
            }
        }
    }
}

@media screen and (max-width: $smallDesktop) {
    .doctors-container {
        .doctors-content {
            position: relative;
            .right-part {
                width: 100%;

                .search-container {
                    width: 100%;
                    align-self: flex-start;
                    .name-container {
                        width: 35%;
                    }
                    .filter-icon {
                        width: 22px;
                        margin-right: auto;
                        margin-left: 15px;
                        display: block;
                    }
                }

                .list-of-doctors {
                    width: 65%;
                }
            }

            .filter-container {
                min-height: 100vh;
                height: 98%;

                width: 35%;

                position: fixed;
                left: 0;
                top: 0;

                overflow-y: scroll;
                overflow-x: hidden;
                z-index: 5;
                &.hide {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .doctors-container {
        .doctors-content {
            .right-part {
                .list-of-doctors {
                    width: 100%;
                }
            }

            .filter-container {
                width: 60%;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .doctors-container {
        .doctors-content {
            .right-part {
                .search-container {
                    flex-direction: column;
                    align-items: center;
                    .name-container {
                        width: 95%;
                        margin-bottom: 10px;
                    }

                    select {
                        width: 95%;
                        margin-bottom: 10px;
                    }

                    .filter-icon {
                        align-self: flex-end;
                    }
                }
            }

            .filter-container {
                width: 90%;
            }
        }
    }
}
