@import '../../../styles/screenSizes.scss';
@import '../../../styles/colors.scss';

.feature-container {
    width: 380px;
    height: 227px;
    padding: 24px 18px;
    margin-top: 5%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #f4fcfd;
    border-radius: 3px;
    text-align: center;

    width: 32%;

    .feature-icon {
        margin-top: -22%;
        margin-bottom: 9%;
    }
    P[selector='title'] {
        color: $text-dark;
        margin-bottom: 0.75rem;
    }
    P[selector='p2'] {
        line-height: 30px;
        font-weight: lighter;
        color: $text-light;
    }
}

@media screen and (max-width: $smallDesktop) {
    .feature-container {
        margin-top: 10%;
        width: 48%;
    }
}

@media screen and (max-width: $tablet) {
    .feature-container {
        width: 80%;
        height: auto;
        margin-top: 15%;
    }
}

@media screen and (max-width: $mobile) {
    .feature-container {
        width: 90%;
        height: auto;
        margin-top: 15%;
    }
}
